<template>
  <b-card>
    <b-card title="基本信息">
      <el-descriptions>
        <el-descriptions-item label="业务名称">
          {{ productInfo.name }}
        </el-descriptions-item>
        <el-descriptions-item label="类型">
          {{ productInfo.productType ? {1: '自研',2:'外购'}[productInfo.productType] : '' }}
        </el-descriptions-item>
        <el-descriptions-item label="服务地区">
          {{ productInfo.serviceArea }}
        </el-descriptions-item>
        <el-descriptions-item label="所属部门">
          {{ productInfo.deptName }}
        </el-descriptions-item>
        <el-descriptions-item label="上线时间">
          {{ productInfo.onlineTime }}
        </el-descriptions-item>
        <el-descriptions-item label="功能描述">
          {{ productInfo.otherDescription }}
        </el-descriptions-item>
        <el-descriptions-item label="业务阶段">
          {{ statusObj[productInfo.status] }}
        </el-descriptions-item>

        <el-descriptions-item label="业务负责人">
          {{ productInfo.managerName }}
        </el-descriptions-item>
        <el-descriptions-item label="技术负责人">
          {{ productInfo.technicalName }}
        </el-descriptions-item>
      </el-descriptions>
    </b-card>
    <b-card title="个人信息主体权力">
      <el-descriptions :column="1">
        <el-descriptions-item label="访问权力实现">
          {{ productInfo.userAccessRights }}
        </el-descriptions-item>
        <el-descriptions-item label="修改权力实现">
          {{ productInfo.userUpdateRights }}
        </el-descriptions-item>
        <el-descriptions-item label="删除权力实现">
          {{ productInfo.userDeleteRights }}
        </el-descriptions-item>
        <el-descriptions-item label="获取个人信息副本权力实现">
          {{ productInfo.userGetRights }}
        </el-descriptions-item>
        <el-descriptions-item label="限制处理权力实现">
          {{ productInfo.userLimitHandleRights }}
        </el-descriptions-item>
        <el-descriptions-item label="反对处理权力实现">
          {{ productInfo.userAntiHandleRights }}
        </el-descriptions-item>
      </el-descriptions>
    </b-card>
    <b-card title="业务场景">
      <el-table
        v-loading="loading"
        fit
        highlight-current-row
        :data="tableData"
        stripe
        border
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          :show-overflow-tooltip="true"
          label="场景名称"
        />
        <el-table-column
          prop="name"
          :show-overflow-tooltip="true"
          label="场景类型"
        >
          <template slot-scope="scope">
            {{ sceneTypeObj[scope.row.sceneType] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="describe"
          :show-overflow-tooltip="true"
          label="场景介绍"
        />
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.pageSize"
        @pagination="getList"
      />
    </b-card>
    <div class="text-center">
      <el-button
        type="primary"
        @click="$router.push({path: '/product'})"
      >
        返 回
      </el-button>
    </div>
  </b-card>
</template>

<script>
import { getProductById, productScenePage } from '@/api/enterprise/product'
import Pagination from '@/@core/components/pagination/Pagination.vue'

export default {
  name: 'ProductDetail',
  components: { Pagination },
  data() {
    return {
      total: 0,
      loading: false,
      tableData: [],
      statusObj: {
        1: '设计阶段',
        2: '测试阶段',
        3: '正式阶段',
        4: '试用阶段',
        5: '弃用',
      },
      sceneTypeObj: {
        1: '基本业务场景',
        2: '拓展业务场景',
      },
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
      },
      productInfo: {
        id: Number(this.$route.query.id),
        productCode: '',
        managerName: '',
        name: '',
        technicalName: '',
        onlineTime: '',
        createBy: '',
        productStage: '',
        productVersion: '',
        userAntiHandleRights: '',
        userLimitHandleRights: '',
        userGetRights: '',
        userDeleteRights: '',
        userUpdateRights: '',
        userAccessRights: '',
        assetsList: [],
      },
    }
  },
  created() {
    this.getProductById()
    this.getList()
  },
  mounted() {
    this.navActiveInit(1, '业务管理', '企业管理')
  },
  destroyed() {
    this.navActiveInit(2, '业务管理', '企业管理', '/product')
  },
  methods: {
    getProductById() {
      getProductById({ id: this.productInfo.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.productInfo = resData.data
        }
      })
    },
    getList() {
      productScenePage(this.productInfo.id, this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
